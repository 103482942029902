import { AppBar, Box, Button, Container, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import './App.css';
import icon from './assets/tannhauser-icon.svg';
import background from './assets/galactic-background.jpeg';
import book from './assets/book.svg';
import kindle from './assets/kindle-icon.svg';
import amazon from './assets/amazon-icon.svg';
import sdrVehicle from './assets/sdr-vehicle.png';
import vallanda from './assets/vallanda.png';
import scarab from './assets/scarab-tower.png';
import hw from './assets/harland-wolf.png';
import darkHedges from './assets/dark-hedges.png';

function HeaderBar() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <AppBar sx={{backgroundColor:  t => t.palette.background.default, height: '60px'}} position="static">
      <Box flex={1} display="flex" p={1} mx={10}>
        <Box flex={1} textAlign="left" display="flex" alignItems="center">
          <img src={icon} height={20} alt="TANNHAUSER ICON"/>
          { !isSmall && <Typography variant="h6" color="primary" ml={2}>
            STEEDMAN BARBOUR
          </Typography> }
        </Box>
        <StartReadingButton />
      </Box>
    </AppBar>
  )
}

function StartReadingButton() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Button variant="contained" target="_blank" href="https://read.amazon.co.uk/kp/embed?asin=B0D3288RY2&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_V6977GT9FXQVV6SS96G0"
    sx={{
      px: 2,
      py: 0.8,
      height: '40px'
    }}>
      {isSmall ? 'READ NOW' : 'START READING NOW' }
    </Button>
  )
}

function BookHero() {
  return (
    <Box display="flex" flexDirection="column" sx={{
      position: 'relative',
      height: 'calc(100% - 60px)',
      zIndex: 1
    }}>
      <Box width="100%" height="100%" position="absolute" sx={{
        zIndex: 2,
        opacity: 0.4,
        background: `url(${background}) right, #000000aa`,
        backgroundBlendMode: 'hard-light'
      }}></Box>
      <Box height="100%" display="flex" flexDirection="column" sx={{zIndex: 3}}>
        <BookFeature />
        <AvailableOn />
      </Box>
    </Box>
  )
}

function BookFeature() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Container maxWidth="lg" sx={{display:'flex', flex: 1, flexDirection: { xs: 'column-reverse', md: 'row'}}}>
      <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center"  mb={4}>
        <Box mr={isSmall ? 3 : 6}>
          <img src={book} width={isSmall ? '100px' : '300px'} alt="TANNHAUSER BOOK"/>
        </Box>
        <Box>
          <StartReadingButton/>
        </Box>
      </Box>
      <Box flex={1} color="white" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box><Typography variant={isSmall ? "h5" : "h1"} fontWeight="bold">Tannhauser</Typography></Box>
        <Box mb={2}><Typography variant={isSmall ? "body1" : "h5"} fontWeight="thin">Part I</Typography></Box>
        <Box mb={{xs: 4, md: 8}}>
          <Typography variant={isSmall ? "body2" : "h6"}>In the cold, unforgiving expanse of space, under the shadow of the ironclad Imperium, the rebellion teetered on the precipice of defeat. The dark sun emblem of the Imperium now dims the galaxy's once vibrant colours.</Typography>
        </Box>
      </Box>
    </Container>
  )
}

function AvailableOn() {
  return (
    <Box sx={{backgroundColor: t => t.palette.background.default}}>
      <Container maxWidth="xl" sx={{ height: 150, display: 'flex', flexDirection: 'column'}}>
        <Box p={2}>
          <Typography color="#808080">
            Available now on
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-evenly" alignItems="center" flexWrap="wrap">
          <Link href="https://www.amazon.co.uk/dp/B0D2V4SCCB" target="_blank">
            <img src={amazon} height="35px" alt="AMAZON"/>
          </Link>
          <Link href="https://www.amazon.co.uk/dp/B0D3288RY2" target="_blank">
            <img src={kindle} height="50px" alt="AMAZON KINDLE" />
          </Link>
        </Box>
      </Container>
    </Box>
  )
}


function FeaturedContent() {
  return (
    <Box display="flex" flexDirection="column" sx={{zIndex: 3}}>
      <Box>
        <img width="100%" src={sdrVehicle} alt="SDR"/>
        <img width="100%" src={vallanda} alt="VALLANDA" />
        <img width="100%" src={scarab} alt="SCARAB TOWER"/>
        <img width="100%" src={hw} alt="HARLAND AND WOLF"/>
        <img width="100%" src={darkHedges} alt="DARK HEDGES"/>
      </Box>
    </Box>
  )
}

function App() {
  return (
    <Box className="App" height="100%" sx={{
      backgroundColor: t => t.palette.background.default,
      position: 'relative'
    }}>
      <HeaderBar />
      <BookHero />
      <FeaturedContent />
    </Box>
  );
}

export default App;
